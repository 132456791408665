import { default as _404MJQkC8AnIJMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/404.vue?macro=true";
import { default as _91titleA_93_45_91idA_93xzwab6kRREMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/actualites/[titleA]-[idA].vue?macro=true";
import { default as indexgDYmPDIVIFMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/actualites/index.vue?macro=true";
import { default as indexyFn2YgPekDMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/bilan_competences/index.vue?macro=true";
import { default as indexdg8obqbm0uMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/bilan_competences/nos_centres/index.vue?macro=true";
import { default as indexFcz6KkteGeMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/entretien_annuel_professionnel/index.vue?macro=true";
import { default as indexLcwnHqb3STMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/gepp/index.vue?macro=true";
import { default as index1DTjbB3RcZMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/marque_employeur/index.vue?macro=true";
import { default as indexe3bIPMqXw9Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/obligations_legales/index.vue?macro=true";
import { default as indexRPiwPm5YlNMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/pcrh/index.vue?macro=true";
import { default as indexwPwGBw3SFgMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/contactez-nous/index.vue?macro=true";
import { default as cookiesssTAuA4EE382Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/cookiesss.vue?macro=true";
import { default as indexMZxg9a2djfMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/equipe-bilan-competences/index.vue?macro=true";
import { default as index3pb5xEBo81Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/equipe/index.vue?macro=true";
import { default as indexW99JpPc2bPMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/formulaire-reclamation/index.vue?macro=true";
import { default as indexXPqcsmJ2PsMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/fr/index.vue?macro=true";
import { default as indexENBlGdFGQuMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/index.vue?macro=true";
import { default as index3fThfA7JszMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/leadership-management/index.vue?macro=true";
import { default as index5RZlcA9PWZMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/accompagnement_codir/index.vue?macro=true";
import { default as indexHo63dkOlenMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/co_developpement/index.vue?macro=true";
import { default as indexK1DKlhysf9Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/coaching_professionnel/index.vue?macro=true";
import { default as indexlIDJdXZ48nMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/conduite_du_changement/index.vue?macro=true";
import { default as indexkN4eYjxI3YMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/team_building/index.vue?macro=true";
import { default as index5av599guQ8Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/mentions-legales/index.vue?macro=true";
import { default as index2lYpkQgkIyMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/offre-emploi/index.vue?macro=true";
import { default as _91idC_93TijTfjHGWUMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/offre-emploi/offres/[idC].vue?macro=true";
import { default as indexq6yin4x6vGMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/offre-emploi/offres/index.vue?macro=true";
import { default as indexzNp7CSxthwMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/organisme-formation/index.vue?macro=true";
import { default as indexByz0EMvtkOMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/preparez-votre-formation/index.vue?macro=true";
import { default as presentation5c5bSxo6y7Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/presentation.vue?macro=true";
import { default as index3gAaW3SG7qMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/audit-social/index.vue?macro=true";
import { default as indexREetb1EkFfMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/gap/index.vue?macro=true";
import { default as indexQZDmgT2QpEMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/mediation/index.vue?macro=true";
import { default as indexWQE0If0FcXMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/prevention-rps/index.vue?macro=true";
import { default as indexgaLhJyJC61Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/supervision/index.vue?macro=true";
import { default as indexAlNWOCjLerMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/recrutement/approche-directe/index.vue?macro=true";
import { default as index6uOZEMS87dMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/recrutement/assessment/index.vue?macro=true";
import { default as indexjrsEmyyV0lMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/recrutement/evaluation-candidat/index.vue?macro=true";
import { default as indexUJNCO6hh70Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/recrutement/recrutement/index.vue?macro=true";
import { default as indexNaW0voL7u8Meta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/ressources-humaines-rse/index.vue?macro=true";
import { default as indexTzAYp9bsMcMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/sessions/index.vue?macro=true";
import { default as indexgLeT4m4O0GMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/soft-skills/index.vue?macro=true";
import { default as indexVFhbipMCeNMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/transition-professionnelle/activ-projet/index.vue?macro=true";
import { default as indexWaNG71UkDDMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/transition-professionnelle/bilan-retraite/index.vue?macro=true";
import { default as indexnuZpArWgcaMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/transition-professionnelle/index.vue?macro=true";
import { default as indexHxLLD1WMmRMeta } from "C:/Users/Hardinah/Desktop/dev/EMCI/pages/transition-professionnelle/outplacement/index.vue?macro=true";
export default [
  {
    name: _404MJQkC8AnIJMeta?.name ?? "404",
    path: _404MJQkC8AnIJMeta?.path ?? "/404",
    meta: _404MJQkC8AnIJMeta || {},
    alias: _404MJQkC8AnIJMeta?.alias || [],
    redirect: _404MJQkC8AnIJMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91titleA_93_45_91idA_93xzwab6kRREMeta?.name ?? "actualites-titleA-idA",
    path: _91titleA_93_45_91idA_93xzwab6kRREMeta?.path ?? "/actualites/:titleA()-:idA()",
    meta: _91titleA_93_45_91idA_93xzwab6kRREMeta || {},
    alias: _91titleA_93_45_91idA_93xzwab6kRREMeta?.alias || [],
    redirect: _91titleA_93_45_91idA_93xzwab6kRREMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/actualites/[titleA]-[idA].vue").then(m => m.default || m)
  },
  {
    name: indexgDYmPDIVIFMeta?.name ?? "actualites",
    path: indexgDYmPDIVIFMeta?.path ?? "/actualites",
    meta: indexgDYmPDIVIFMeta || {},
    alias: indexgDYmPDIVIFMeta?.alias || [],
    redirect: indexgDYmPDIVIFMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/actualites/index.vue").then(m => m.default || m)
  },
  {
    name: indexyFn2YgPekDMeta?.name ?? "bilan_competences",
    path: indexyFn2YgPekDMeta?.path ?? "/bilan_competences",
    meta: indexyFn2YgPekDMeta || {},
    alias: indexyFn2YgPekDMeta?.alias || [],
    redirect: indexyFn2YgPekDMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/bilan_competences/index.vue").then(m => m.default || m)
  },
  {
    name: indexdg8obqbm0uMeta?.name ?? "bilan_competences-nos_centres",
    path: indexdg8obqbm0uMeta?.path ?? "/bilan_competences/nos_centres",
    meta: indexdg8obqbm0uMeta || {},
    alias: indexdg8obqbm0uMeta?.alias || [],
    redirect: indexdg8obqbm0uMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/bilan_competences/nos_centres/index.vue").then(m => m.default || m)
  },
  {
    name: indexFcz6KkteGeMeta?.name ?? "conseil_rh-entretien_annuel_professionnel",
    path: indexFcz6KkteGeMeta?.path ?? "/conseil_rh/entretien_annuel_professionnel",
    meta: indexFcz6KkteGeMeta || {},
    alias: indexFcz6KkteGeMeta?.alias || [],
    redirect: indexFcz6KkteGeMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/entretien_annuel_professionnel/index.vue").then(m => m.default || m)
  },
  {
    name: indexLcwnHqb3STMeta?.name ?? "conseil_rh-gepp",
    path: indexLcwnHqb3STMeta?.path ?? "/conseil_rh/gepp",
    meta: indexLcwnHqb3STMeta || {},
    alias: indexLcwnHqb3STMeta?.alias || [],
    redirect: indexLcwnHqb3STMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/gepp/index.vue").then(m => m.default || m)
  },
  {
    name: index1DTjbB3RcZMeta?.name ?? "conseil_rh-marque_employeur",
    path: index1DTjbB3RcZMeta?.path ?? "/conseil_rh/marque_employeur",
    meta: index1DTjbB3RcZMeta || {},
    alias: index1DTjbB3RcZMeta?.alias || [],
    redirect: index1DTjbB3RcZMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/marque_employeur/index.vue").then(m => m.default || m)
  },
  {
    name: indexe3bIPMqXw9Meta?.name ?? "conseil_rh-obligations_legales",
    path: indexe3bIPMqXw9Meta?.path ?? "/conseil_rh/obligations_legales",
    meta: indexe3bIPMqXw9Meta || {},
    alias: indexe3bIPMqXw9Meta?.alias || [],
    redirect: indexe3bIPMqXw9Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/obligations_legales/index.vue").then(m => m.default || m)
  },
  {
    name: indexRPiwPm5YlNMeta?.name ?? "conseil_rh-pcrh",
    path: indexRPiwPm5YlNMeta?.path ?? "/conseil_rh/pcrh",
    meta: indexRPiwPm5YlNMeta || {},
    alias: indexRPiwPm5YlNMeta?.alias || [],
    redirect: indexRPiwPm5YlNMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/conseil_rh/pcrh/index.vue").then(m => m.default || m)
  },
  {
    name: indexwPwGBw3SFgMeta?.name ?? "contactez-nous",
    path: indexwPwGBw3SFgMeta?.path ?? "/contactez-nous",
    meta: indexwPwGBw3SFgMeta || {},
    alias: indexwPwGBw3SFgMeta?.alias || [],
    redirect: indexwPwGBw3SFgMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/contactez-nous/index.vue").then(m => m.default || m)
  },
  {
    name: cookiesssTAuA4EE382Meta?.name ?? "cookiesss",
    path: cookiesssTAuA4EE382Meta?.path ?? "/cookiesss",
    meta: cookiesssTAuA4EE382Meta || {},
    alias: cookiesssTAuA4EE382Meta?.alias || [],
    redirect: cookiesssTAuA4EE382Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/cookiesss.vue").then(m => m.default || m)
  },
  {
    name: indexMZxg9a2djfMeta?.name ?? "equipe-bilan-competences",
    path: indexMZxg9a2djfMeta?.path ?? "/equipe-bilan-competences",
    meta: indexMZxg9a2djfMeta || {},
    alias: indexMZxg9a2djfMeta?.alias || [],
    redirect: indexMZxg9a2djfMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/equipe-bilan-competences/index.vue").then(m => m.default || m)
  },
  {
    name: index3pb5xEBo81Meta?.name ?? "equipe",
    path: index3pb5xEBo81Meta?.path ?? "/equipe",
    meta: index3pb5xEBo81Meta || {},
    alias: index3pb5xEBo81Meta?.alias || [],
    redirect: index3pb5xEBo81Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/equipe/index.vue").then(m => m.default || m)
  },
  {
    name: indexW99JpPc2bPMeta?.name ?? "formulaire-reclamation",
    path: indexW99JpPc2bPMeta?.path ?? "/formulaire-reclamation",
    meta: indexW99JpPc2bPMeta || {},
    alias: indexW99JpPc2bPMeta?.alias || [],
    redirect: indexW99JpPc2bPMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/formulaire-reclamation/index.vue").then(m => m.default || m)
  },
  {
    name: indexXPqcsmJ2PsMeta?.name ?? "fr",
    path: indexXPqcsmJ2PsMeta?.path ?? "/fr",
    meta: indexXPqcsmJ2PsMeta || {},
    alias: indexXPqcsmJ2PsMeta?.alias || [],
    redirect: indexXPqcsmJ2PsMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/fr/index.vue").then(m => m.default || m)
  },
  {
    name: indexENBlGdFGQuMeta?.name ?? "index",
    path: indexENBlGdFGQuMeta?.path ?? "/",
    meta: indexENBlGdFGQuMeta || {},
    alias: indexENBlGdFGQuMeta?.alias || [],
    redirect: indexENBlGdFGQuMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index3fThfA7JszMeta?.name ?? "leadership-management",
    path: index3fThfA7JszMeta?.path ?? "/leadership-management",
    meta: index3fThfA7JszMeta || {},
    alias: index3fThfA7JszMeta?.alias || [],
    redirect: index3fThfA7JszMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/leadership-management/index.vue").then(m => m.default || m)
  },
  {
    name: index5RZlcA9PWZMeta?.name ?? "management-accompagnement_codir",
    path: index5RZlcA9PWZMeta?.path ?? "/management/accompagnement_codir",
    meta: index5RZlcA9PWZMeta || {},
    alias: index5RZlcA9PWZMeta?.alias || [],
    redirect: index5RZlcA9PWZMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/accompagnement_codir/index.vue").then(m => m.default || m)
  },
  {
    name: indexHo63dkOlenMeta?.name ?? "management-co_developpement",
    path: indexHo63dkOlenMeta?.path ?? "/management/co_developpement",
    meta: indexHo63dkOlenMeta || {},
    alias: indexHo63dkOlenMeta?.alias || [],
    redirect: indexHo63dkOlenMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/co_developpement/index.vue").then(m => m.default || m)
  },
  {
    name: indexK1DKlhysf9Meta?.name ?? "management-coaching_professionnel",
    path: indexK1DKlhysf9Meta?.path ?? "/management/coaching_professionnel",
    meta: indexK1DKlhysf9Meta || {},
    alias: indexK1DKlhysf9Meta?.alias || [],
    redirect: indexK1DKlhysf9Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/coaching_professionnel/index.vue").then(m => m.default || m)
  },
  {
    name: indexlIDJdXZ48nMeta?.name ?? "management-conduite_du_changement",
    path: indexlIDJdXZ48nMeta?.path ?? "/management/conduite_du_changement",
    meta: indexlIDJdXZ48nMeta || {},
    alias: indexlIDJdXZ48nMeta?.alias || [],
    redirect: indexlIDJdXZ48nMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/conduite_du_changement/index.vue").then(m => m.default || m)
  },
  {
    name: indexkN4eYjxI3YMeta?.name ?? "management-team_building",
    path: indexkN4eYjxI3YMeta?.path ?? "/management/team_building",
    meta: indexkN4eYjxI3YMeta || {},
    alias: indexkN4eYjxI3YMeta?.alias || [],
    redirect: indexkN4eYjxI3YMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/management/team_building/index.vue").then(m => m.default || m)
  },
  {
    name: index5av599guQ8Meta?.name ?? "mentions-legales",
    path: index5av599guQ8Meta?.path ?? "/mentions-legales",
    meta: index5av599guQ8Meta || {},
    alias: index5av599guQ8Meta?.alias || [],
    redirect: index5av599guQ8Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/mentions-legales/index.vue").then(m => m.default || m)
  },
  {
    name: index2lYpkQgkIyMeta?.name ?? "offre-emploi",
    path: index2lYpkQgkIyMeta?.path ?? "/offre-emploi",
    meta: index2lYpkQgkIyMeta || {},
    alias: index2lYpkQgkIyMeta?.alias || [],
    redirect: index2lYpkQgkIyMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/offre-emploi/index.vue").then(m => m.default || m)
  },
  {
    name: _91idC_93TijTfjHGWUMeta?.name ?? "offre-emploi-offres-idC",
    path: _91idC_93TijTfjHGWUMeta?.path ?? "/offre-emploi/offres/:idC()",
    meta: _91idC_93TijTfjHGWUMeta || {},
    alias: _91idC_93TijTfjHGWUMeta?.alias || [],
    redirect: _91idC_93TijTfjHGWUMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/offre-emploi/offres/[idC].vue").then(m => m.default || m)
  },
  {
    name: indexq6yin4x6vGMeta?.name ?? "offre-emploi-offres",
    path: indexq6yin4x6vGMeta?.path ?? "/offre-emploi/offres",
    meta: indexq6yin4x6vGMeta || {},
    alias: indexq6yin4x6vGMeta?.alias || [],
    redirect: indexq6yin4x6vGMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/offre-emploi/offres/index.vue").then(m => m.default || m)
  },
  {
    name: indexzNp7CSxthwMeta?.name ?? "organisme-formation",
    path: indexzNp7CSxthwMeta?.path ?? "/organisme-formation",
    meta: indexzNp7CSxthwMeta || {},
    alias: indexzNp7CSxthwMeta?.alias || [],
    redirect: indexzNp7CSxthwMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/organisme-formation/index.vue").then(m => m.default || m)
  },
  {
    name: indexByz0EMvtkOMeta?.name ?? "preparez-votre-formation",
    path: indexByz0EMvtkOMeta?.path ?? "/preparez-votre-formation",
    meta: indexByz0EMvtkOMeta || {},
    alias: indexByz0EMvtkOMeta?.alias || [],
    redirect: indexByz0EMvtkOMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/preparez-votre-formation/index.vue").then(m => m.default || m)
  },
  {
    name: presentation5c5bSxo6y7Meta?.name ?? "presentation",
    path: presentation5c5bSxo6y7Meta?.path ?? "/presentation",
    meta: presentation5c5bSxo6y7Meta || {},
    alias: presentation5c5bSxo6y7Meta?.alias || [],
    redirect: presentation5c5bSxo6y7Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/presentation.vue").then(m => m.default || m)
  },
  {
    name: index3gAaW3SG7qMeta?.name ?? "qualite-vie-condition-travail-audit-social",
    path: index3gAaW3SG7qMeta?.path ?? "/qualite-vie-condition-travail/audit-social",
    meta: index3gAaW3SG7qMeta || {},
    alias: index3gAaW3SG7qMeta?.alias || [],
    redirect: index3gAaW3SG7qMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/audit-social/index.vue").then(m => m.default || m)
  },
  {
    name: indexREetb1EkFfMeta?.name ?? "qualite-vie-condition-travail-gap",
    path: indexREetb1EkFfMeta?.path ?? "/qualite-vie-condition-travail/gap",
    meta: indexREetb1EkFfMeta || {},
    alias: indexREetb1EkFfMeta?.alias || [],
    redirect: indexREetb1EkFfMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/gap/index.vue").then(m => m.default || m)
  },
  {
    name: indexQZDmgT2QpEMeta?.name ?? "qualite-vie-condition-travail-mediation",
    path: indexQZDmgT2QpEMeta?.path ?? "/qualite-vie-condition-travail/mediation",
    meta: indexQZDmgT2QpEMeta || {},
    alias: indexQZDmgT2QpEMeta?.alias || [],
    redirect: indexQZDmgT2QpEMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/mediation/index.vue").then(m => m.default || m)
  },
  {
    name: indexWQE0If0FcXMeta?.name ?? "qualite-vie-condition-travail-prevention-rps",
    path: indexWQE0If0FcXMeta?.path ?? "/qualite-vie-condition-travail/prevention-rps",
    meta: indexWQE0If0FcXMeta || {},
    alias: indexWQE0If0FcXMeta?.alias || [],
    redirect: indexWQE0If0FcXMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/prevention-rps/index.vue").then(m => m.default || m)
  },
  {
    name: indexgaLhJyJC61Meta?.name ?? "qualite-vie-condition-travail-supervision",
    path: indexgaLhJyJC61Meta?.path ?? "/qualite-vie-condition-travail/supervision",
    meta: indexgaLhJyJC61Meta || {},
    alias: indexgaLhJyJC61Meta?.alias || [],
    redirect: indexgaLhJyJC61Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/qualite-vie-condition-travail/supervision/index.vue").then(m => m.default || m)
  },
  {
    name: indexAlNWOCjLerMeta?.name ?? "recrutement-approche-directe",
    path: indexAlNWOCjLerMeta?.path ?? "/recrutement/approche-directe",
    meta: indexAlNWOCjLerMeta || {},
    alias: indexAlNWOCjLerMeta?.alias || [],
    redirect: indexAlNWOCjLerMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/recrutement/approche-directe/index.vue").then(m => m.default || m)
  },
  {
    name: index6uOZEMS87dMeta?.name ?? "recrutement-assessment",
    path: index6uOZEMS87dMeta?.path ?? "/recrutement/assessment",
    meta: index6uOZEMS87dMeta || {},
    alias: index6uOZEMS87dMeta?.alias || [],
    redirect: index6uOZEMS87dMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/recrutement/assessment/index.vue").then(m => m.default || m)
  },
  {
    name: indexjrsEmyyV0lMeta?.name ?? "recrutement-evaluation-candidat",
    path: indexjrsEmyyV0lMeta?.path ?? "/recrutement/evaluation-candidat",
    meta: indexjrsEmyyV0lMeta || {},
    alias: indexjrsEmyyV0lMeta?.alias || [],
    redirect: indexjrsEmyyV0lMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/recrutement/evaluation-candidat/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJNCO6hh70Meta?.name ?? "recrutement-recrutement",
    path: indexUJNCO6hh70Meta?.path ?? "/recrutement/recrutement",
    meta: indexUJNCO6hh70Meta || {},
    alias: indexUJNCO6hh70Meta?.alias || [],
    redirect: indexUJNCO6hh70Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/recrutement/recrutement/index.vue").then(m => m.default || m)
  },
  {
    name: indexNaW0voL7u8Meta?.name ?? "ressources-humaines-rse",
    path: indexNaW0voL7u8Meta?.path ?? "/ressources-humaines-rse",
    meta: indexNaW0voL7u8Meta || {},
    alias: indexNaW0voL7u8Meta?.alias || [],
    redirect: indexNaW0voL7u8Meta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/ressources-humaines-rse/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzAYp9bsMcMeta?.name ?? "sessions",
    path: indexTzAYp9bsMcMeta?.path ?? "/sessions",
    meta: indexTzAYp9bsMcMeta || {},
    alias: indexTzAYp9bsMcMeta?.alias || [],
    redirect: indexTzAYp9bsMcMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/sessions/index.vue").then(m => m.default || m)
  },
  {
    name: indexgLeT4m4O0GMeta?.name ?? "soft-skills",
    path: indexgLeT4m4O0GMeta?.path ?? "/soft-skills",
    meta: indexgLeT4m4O0GMeta || {},
    alias: indexgLeT4m4O0GMeta?.alias || [],
    redirect: indexgLeT4m4O0GMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/soft-skills/index.vue").then(m => m.default || m)
  },
  {
    name: indexVFhbipMCeNMeta?.name ?? "transition-professionnelle-activ-projet",
    path: indexVFhbipMCeNMeta?.path ?? "/transition-professionnelle/activ-projet",
    meta: indexVFhbipMCeNMeta || {},
    alias: indexVFhbipMCeNMeta?.alias || [],
    redirect: indexVFhbipMCeNMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/transition-professionnelle/activ-projet/index.vue").then(m => m.default || m)
  },
  {
    name: indexWaNG71UkDDMeta?.name ?? "transition-professionnelle-bilan-retraite",
    path: indexWaNG71UkDDMeta?.path ?? "/transition-professionnelle/bilan-retraite",
    meta: indexWaNG71UkDDMeta || {},
    alias: indexWaNG71UkDDMeta?.alias || [],
    redirect: indexWaNG71UkDDMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/transition-professionnelle/bilan-retraite/index.vue").then(m => m.default || m)
  },
  {
    name: indexnuZpArWgcaMeta?.name ?? "transition-professionnelle",
    path: indexnuZpArWgcaMeta?.path ?? "/transition-professionnelle",
    meta: indexnuZpArWgcaMeta || {},
    alias: indexnuZpArWgcaMeta?.alias || [],
    redirect: indexnuZpArWgcaMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/transition-professionnelle/index.vue").then(m => m.default || m)
  },
  {
    name: indexHxLLD1WMmRMeta?.name ?? "transition-professionnelle-outplacement",
    path: indexHxLLD1WMmRMeta?.path ?? "/transition-professionnelle/outplacement",
    meta: indexHxLLD1WMmRMeta || {},
    alias: indexHxLLD1WMmRMeta?.alias || [],
    redirect: indexHxLLD1WMmRMeta?.redirect,
    component: () => import("C:/Users/Hardinah/Desktop/dev/EMCI/pages/transition-professionnelle/outplacement/index.vue").then(m => m.default || m)
  }
]